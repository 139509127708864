import Link from "next/link";
import React, { useEffect, useState } from "react";
import ProductImage from "../products/product-image";
import QuantityControl from "../controls/quantity-control";
import { Button } from "../ui/button";
import FavoriteButton from "../controls/favorite-button";
import { Loader2, Trash2 } from "lucide-react";
import { ProductPriceGroupTitle } from "../products/product-price-group";
import { Separator } from "../ui/separator";
import { SaleOrderItem } from "@/util/services/cart/api.type";
import { useTranslation } from "@/app/i18n/client";
import { toPriceString } from "@/util/util";
import { CUSTOMER_LEVEL } from "@prisma/client";
import _debounce from "lodash.debounce";
import { useCart } from "@/lib/providers/cart-provider";
import { useRouter } from "next/navigation";
import toast from "react-hot-toast";
import { debounceTime, Subject, switchMap } from "rxjs";

const CartActionControl = ({
    handle,
    className,
    onDelete,
    isDeleting,
}: {
    handle: string;
    className: string;
    onDelete?: () => void;
    isDeleting?: boolean;
}) => {
    return (
        <div className={className}>
            <FavoriteButton
                productHandle={handle}
                favClassName="bg-trasnparent"
                unFavClassName="bg-transparent"
                favIconColor="text-c2 fill-c2"
            />
            <Button variant={"icon"} size="icon" className="bg-transparent" onClick={onDelete} isLoading={isDeleting}>
                <Trash2 size={20} />
            </Button>
        </div>
    );
};

interface Props {
    item: SaleOrderItem;
    level: CUSTOMER_LEVEL;
    needCalculateCart?: boolean;
}

const CartListItem = ({ item, level, needCalculateCart }: Props) => {
    const { loc, t } = useTranslation();
    const { updateItemQuantity, deleteItem, getQuantityForItem, getOtherLineQuantityForItem, varaintLock } = useCart();
    const router = useRouter();

    const productAttr = item.msProduct.attributes.find((attr) => attr.loc === loc);
    const productVaraintAttr = item.msProductVariant.attributes.find((attr) => attr.loc === loc);

    const fullPrice = item.msProductVariant.priceFull;
    const v = item.msProductVariant;
    const userPrice =
        level === "BP_VVIP"
            ? v.priceVVIP
            : level === "BP_VIP"
              ? v.priceVIP
              : level === "NORMAL"
                ? v.priceTrial
                : v.priceFull;
    const totalUserPrice = item.netAmount;
    const totalFullPrice = item.quantity * item.price;

    const [onQuantitySubject$] = useState(() => new Subject<{ item: SaleOrderItem; quantity: number }>());

    const [isDeleting, setDeleteing] = useState<boolean>(false);
    const [quantity, setQuantity] = useState<number>(item.quantity);

    const quantityInCart = getQuantityForItem(v.msProductHandle);
    const otherLineQuantity = getOtherLineQuantityForItem(v.msProductHandle, item.lineId);
    const stock = v.inventory - v.inventory_used;
    const isOutOfStock = stock <= 0;
    const isExceedStock = quantityInCart > stock;
    const maxQuantity =
        stock - otherLineQuantity > v.quantity_rule_max ? v.quantity_rule_max : stock - otherLineQuantity;

    useEffect(() => {
        const subscription = onQuantitySubject$
            .pipe(
                debounceTime(500),
                switchMap((i) =>
                    updateItemQuantity(
                        {
                            idProduct: i.item.msProduct.id,
                            idVariant: i.item.msProductVariantId,
                            updateQty: i.quantity,
                            lineId: i.item.lineId,
                        },
                        needCalculateCart,
                    ),
                ),
            )
            .subscribe({
                next: () => console.log("completed"),
                error: (error) => setQuantity(item.quantity),
            });
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const handleOnDelete = async () => {
        setDeleteing(true);
        try {
            await deleteItem(
                {
                    idProduct: item.msProduct.id,
                    idVariant: item.msProductVariantId,
                    lineId: item.lineId,
                },
                true,
            );
        } catch (error: any) {
            toast.error(error.message, { position: "bottom-center" });
        } finally {
            setDeleteing(false);
        }
    };

    const onQuantityChanged = async (quantity: number) => {
        setQuantity(quantity);
        onQuantitySubject$.next({
            item,
            quantity,
        });
    };

    return (
        <div className="flex gap-4 xs:flex-row flex-col">
            <div className="flex gap-4 w-full">
                <div className="flex-1 max-w-44">
                    <ProductImage
                        href={`/products/${item.msProductHandle}`}
                        src={item.msProductVariant.featuredImageUrl ?? item.msProduct.featuredImageUrl ?? ""}
                        alt={item.msProductHandle}
                        width={180}
                        height={180}
                    />
                </div>
                <div className="flex flex-col xs:justify-between gap-2 flex-[2]">
                    <Link href={`/products/${item.msProductHandle}`} className="">
                        <h5 className="text-c4 hover:text-c2 line-clamp-2">{productAttr?.displayName}</h5>
                    </Link>
                    <div className="flex gap-4">
                        <p className="text-sm font-light text-c4">{productVaraintAttr?.opttion1}</p>
                        <p className="text-sm font-light text-c4">
                            {t("product.weight", { weight: item.msProductVariant.weight })}
                        </p>
                    </div>
                    {!isOutOfStock && (
                        <div className="hidden xs:flex">
                            <QuantityControl
                                value={quantity}
                                onQuantityChanged={onQuantityChanged}
                                min={v.quantity_rule_min}
                                max={maxQuantity}
                                step={v.quantity_rule_increment_step}
                            />
                        </div>
                    )}
                    {isOutOfStock && (
                        <div className="hidden xs:flex">
                            <p className="text-lg font-normal text-error">{t("product.out_of_stock")}</p>
                        </div>
                    )}
                    {isExceedStock && !isOutOfStock && (
                        <div className="hidden xs:flex">
                            <p className="text-lg font-normal text-error">{t("product.exceed_stock")}</p>
                        </div>
                    )}
                    <CartActionControl
                        handle={item.msProductHandle}
                        className="gap-2 hidden xs:flex -ml-2"
                        onDelete={handleOnDelete}
                        isDeleting={isDeleting}
                    />
                </div>
            </div>
            <div className="flex flex-shrink-0 pb-1 max-xs:justify-between max-xs:items-center">
                <div className="flex xs:hidden flex-col gap-0 items-start">
                    {!isOutOfStock && (
                        <QuantityControl
                            value={quantity}
                            onQuantityChanged={onQuantityChanged}
                            min={v.quantity_rule_min}
                            max={maxQuantity}
                            step={v.quantity_rule_increment_step}
                        />
                    )}
                    {isOutOfStock && (
                        <div className="mt-2">
                            <p className="text-lg font-normal text-error">{t("product.out_of_stock")}</p>
                        </div>
                    )}
                    {isExceedStock && !isOutOfStock && (
                        <div className="mt-2">
                            <p className="text-lg font-normal text-error">{t("product.exceed_stock")}</p>
                        </div>
                    )}
                    <CartActionControl
                        handle={item.msProductHandle}
                        className="gap-2 -ml-2"
                        onDelete={handleOnDelete}
                        isDeleting={isDeleting}
                    />
                </div>
                <div className="text-c4 flex flex-col h-full justify-between items-end">
                    <div className="flex flex-col gap-4 items-end">
                        {level === "BP_VVIP" && (
                            <div className="text-vvip">
                                <ProductPriceGroupTitle type="vvip" />
                            </div>
                        )}
                        {level === "BP_VIP" && (
                            <div className="text-price">
                                <ProductPriceGroupTitle type="vip" />
                            </div>
                        )}
                        {level === "NORMAL" && <h5>{t("product.trial_price")}</h5>}
                        {level === "GUEST" && <h5>{t("product.price")}</h5>}
                        <div className="flex xs:flex-col flex-row xs:items-end items-baseline gap-2 xs:gap-0">
                            {varaintLock[item.msProductVariantId] && (
                                <div>
                                    <Loader2 className="animate-spin text-grayc2" />
                                </div>
                            )}
                            {!varaintLock[item.msProductVariantId] && (
                                <div>
                                    <h3 className="font-medium">{`${toPriceString(
                                        totalUserPrice,
                                    )} ${productVaraintAttr?.shortCurrency}`}</h3>
                                </div>
                            )}
                            {totalUserPrice !== totalFullPrice && varaintLock[item.msProductVariantId] && (
                                <div>
                                    <Loader2 className="w-4 h-4 animate-spin text-grayc2" />
                                </div>
                            )}
                            {totalUserPrice !== totalFullPrice && !varaintLock[item.msProductVariantId] && (
                                <div>
                                    <p className="font-medium text-xs line-through">{`${toPriceString(
                                        totalFullPrice,
                                    )} ${productVaraintAttr?.shortCurrency}`}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {level == "BP_VIP" || level === "BP_VVIP" || level === "BP" ? (
                        <div className="flex justify-between text-c4 w-full items-center gap-2">
                            <div>
                                <p className="text-lg font-normal">{`CV: ${item.CV}`}</p>
                            </div>
                            <Separator orientation="vertical" className="h-6" />
                            <div>
                                <p className="text-lg font-normal">{`QV: ${item.QV}`}</p>
                            </div>
                        </div>
                    ) : (
                        <div className="h-5"></div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CartListItem;
