"use client";

import { getUser } from "@/actions";
import { iconpath } from "@/util/staticresourcespath";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { useState, useEffect } from "react";
import Image from "next/image";
import { useAppDispatch, useAppSelector } from "@/lib/store/hook";
import Link from "next/link";
import { setSocialModal } from "@/lib/store/slices/application/application.slice";
import { toast } from "react-hot-toast";
import { getSession } from "next-auth/react";
import { copyToClipboard, copyToIOSClipboard, isEncoded } from "@/util/util";
import { Forward } from "lucide-react";
import { useTranslation } from "react-i18next";
interface SocialMedia {
    id: string;
    name: string;
    iconUrl: string;
    linkUrl: string;
}

const socials: SocialMedia[] = [
    {
        id: "Facebook",
        name: "Facebook",
        iconUrl: "Facebook.png",
        linkUrl: "https://www.facebook.com/sharer.php?u={{currentPath}}",
    },
    {
        id: "Line",
        name: "Line",
        iconUrl: "LINE_Brand_icon.png",
        linkUrl: "http://line.me/R/msg/text/?{{currentPath}}",
    },
    {
        id: "X",
        name: "X",
        iconUrl: "X.png",
        linkUrl: "https://twitter.com/share?text=&url={{currentPath}}",
    },
    {
        id: "CopyLink",
        name: "Copy Link",
        iconUrl: "copy-link.png",
        linkUrl: "{{currentPath}}",
    },
];

export function ShareSocialModal() {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [currentPath, setCurrentPath] = useState("");
    const [customerAccount, setCustomerAccount] = useState<string | null>();

    const { isSocialModelOpen } = useAppSelector((state) => state.ApplicationReducer);

    useEffect(() => {
        let paramId: string | undefined = undefined;
        if (customerAccount) {
            const cid = customerAccount;
            paramId = `cid=${cid}`;
        }
        const queryString = [paramId, "channel=DTC_sharelink"].filter((x) => x).join("&");

        if (isEncoded(window.location.href)) {
            setCurrentPath(`${window.location.href}?${queryString}`);
        } else {
            setCurrentPath(encodeURI(`${window.location.href}?${queryString}`));
        }
    }, [customerAccount]);

    useEffect(() => {
        const getCustomerAccount = async () => {
            const session = await getSession();
            if (session) {
                const user = await getUser();
                if (user && user.level !== "GUEST" && user.level !== "NORMAL") {
                    setCustomerAccount(user.customerAccount);
                }
            }
        };
        getCustomerAccount();
    }, []);

    const handleOnClick = async (id: string) => {
        if (id === "CopyLink") {
            try {
                await copyToClipboard(currentPath);
                toast.success("Copied to clipboard", { position: "bottom-center" });
            } catch (err) {
                try {
                    await copyToIOSClipboard(currentPath);
                    toast.success("Copied to clipboard", { position: "bottom-center" });
                } catch (err: any) {
                    toast.error("(4) Failed to copy: " + err.message, { position: "bottom-center" });
                }
            }
            return;
        }
    };

    return (
        <>
            <Dialog open={isSocialModelOpen} onOpenChange={() => dispatch(setSocialModal(false))}>
                <DialogContent className="flex p-0 border-none sm:max-w-[400px] max-w-[300px]  bg-white">
                    <div className="flex flex-col rounded-lg  shadow-md gap-4 px-6 py-4 w-full h-56">
                        <div className="flex justify-between">
                            <div className="flex flex-row gap-2">
                                <Forward className="text-c2" />
                                <div className="font-font1 text-base">{t("share.btn_share")}</div>
                            </div>
                        </div>
                        <div className="font-normal text-base">
                            {t("share.recommend_business")}
                            <br />
                            {t("share.open_opportunities")}
                        </div>
                        <div className="flex flex-row gap-6">
                            {socials.map((socail: SocialMedia) =>
                                socail.id === "CopyLink" ? (
                                    <button
                                        key={socail.id}
                                        type="button"
                                        className="focus:outline-none"
                                        onClick={() => handleOnClick(socail.id)}>
                                        <div className="flex flex-col gap-2 text-center">
                                            <Image
                                                src={iconpath + socail.iconUrl}
                                                alt={""}
                                                width={48}
                                                height={48}
                                                className="self-center"
                                            />
                                            <div className="font-light text-xs">{socail.name}</div>
                                        </div>
                                    </button>
                                ) : (
                                    <Link
                                        key={socail.id}
                                        href={socail.linkUrl.replace(
                                            "{{currentPath}}",
                                            encodeURIComponent(currentPath),
                                        )}
                                        target="_blank">
                                        <div className="flex flex-col gap-2 text-center">
                                            <Image
                                                src={iconpath + socail.iconUrl}
                                                alt={""}
                                                width={48}
                                                height={48}
                                                className="self-center"
                                            />
                                            <div className="font-light text-xs">{socail.name}</div>
                                        </div>
                                    </Link>
                                ),
                            )}
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
}
