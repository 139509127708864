"use client";

import * as NavigationMenu from "@radix-ui/react-navigation-menu";
import { ChevronDown } from "lucide-react";
import { cn } from "@/lib/utils";
import "./nav-ulife.css";
import Link from "next/link";
import Image from "next/image";
import { useState } from "react";
import { NavLink } from "./nav-link";
import { WebNavigationMenu } from "@/util/query/navigation-query";
import { encodeDynamicParts, getLinkTarget } from "@/util/util";

type Props = {
    menu: WebNavigationMenu;
};

export const DisplayAttributes = ({ menu }: Props) => {
    if (!menu.attributes || menu.attributes.length === 0) {
        return null;
    }

    const selectedAtb = menu.attributes[0];
    if (menu.type === "TEXT" || menu.type === "MASTER") {
        return (
            <NavigationMenu.Item className="list-none">
                <NavLink
                    href={encodeDynamicParts(selectedAtb.linkUrl)}
                    target={getLinkTarget(menu.linkTarget || "SELF")}>
                    {selectedAtb.displayName}
                </NavLink>
            </NavigationMenu.Item>
        );
        // } else if (menu.type === "ICON") {
        //     return (
        //         <NavigationMenu.Item className="list-none">
        //             <NavLink href={selectedAtb.linkUrl} target={getLinkTarget(menu.linkTarget || "SELF")}>
        //                 {selectedAtb.displayName}
        //                 <div className="relative">
        //                     <Image
        //                         src={selectedAtb.imageUrl}
        //                         alt={selectedAtb.imageAlt}
        //                         width={0}
        //                         height={0}
        //                         style={{ width: "auto", height: "30px" }}
        //                         unoptimized
        //                     />
        //                 </div>
        //             </NavLink>
        //         </NavigationMenu.Item>
        //     );
    } else {
        return null;
    }
};

export const DisplayMenuList = ({ menu }: Props) => {
    const selectedAtb = menu.attributes[0];
    const IsIconType = menu.subMenuList.find((value) => value.type === "ICON");
    const haveTextType = menu.subMenuList.find((value) => value.type !== "ICON");
    const [selectedMenu, setSelectedMenu] = useState<WebNavigationMenu | null>(null);
    const [selectedSubMenu, setSelectedSubMenu] = useState<WebNavigationMenu[] | null>(null);

    return (
        <NavigationMenu.Item className="list-none" value={menu.id}>
            <NavigationMenu.Trigger className="">
                <Link
                    href={encodeDynamicParts(selectedAtb.linkUrl)}
                    onMouseOver={() => {
                        setSelectedMenu(null);
                        setSelectedSubMenu(null);
                    }}
                    className="flex gap-1 items-center text-c4 text-sm focus:text-c2 font-light hover:text-c2 focus:font-semibold">
                    {selectedAtb.displayName}
                    <ChevronDown className="ChevronDown h-3 w-3" aria-hidden="true" />
                </Link>
            </NavigationMenu.Trigger>
            <NavigationMenu.Content
                className={cn("NavigationMenuContent flex w-full px-4 sm:px-10 py-8 overflow-auto")}>
                <div
                    className={cn(
                        "NavigationMenuContentDiv flex w-full gap-10 mx-auto max-w-screen-2xl",
                        IsIconType && !haveTextType ? "flex-row" : "flex-row",
                    )}>
                    <div
                        className={cn(
                            "flex justify-start items-start",
                            IsIconType && !haveTextType ? "flex-wrap gap-6" : "flex-col gap-2",
                        )}>
                        {haveTextType ? (
                            selectedAtb.linkUrl ? (
                                <Link
                                    className="font-light text-xs text-grayc3 hover:text-c2 w-fit"
                                    href={encodeDynamicParts(selectedAtb.linkUrl)}>
                                    {selectedAtb.displayName}
                                </Link>
                            ) : (
                                <p className="font-light text-xs text-grayc3">{selectedAtb.displayName}</p>
                            )
                        ) : null}
                        {menu.subMenuList.map((subMenu: any) => {
                            return (
                                <NavLink
                                    key={subMenu.id}
                                    href={encodeDynamicParts(subMenu.attributes[0].linkUrl)}
                                    target={getLinkTarget(subMenu.linkTarget || "SELF")}
                                    onMouseOver={() => {
                                        setSelectedMenu(subMenu);
                                        setSelectedSubMenu(subMenu.subMenuList);
                                    }}
                                    className={cn(
                                        "flex flex-col gap-4 w-fit text-base hover:text-c2 font-light text-center",
                                        selectedMenu && selectedMenu.id === subMenu.id && selectedMenu.type !== "ICON"
                                            ? "font-medium text-black"
                                            : "font-light text-gray",
                                        subMenu.type === "ICON" && haveTextType ? "text-start" : "",
                                    )}>
                                    {subMenu.type === "ICON" ? (
                                        <div className="relative">
                                            <Image
                                                src={subMenu.attributes[0].imageUrl}
                                                alt={subMenu.attributes[0].imageAlt}
                                                width={0}
                                                height={0}
                                                style={{ width: "auto", height: "120px" }}
                                                unoptimized
                                            />
                                        </div>
                                    ) : null}
                                    <span> {subMenu.attributes[0].displayName}</span>
                                </NavLink>
                            );
                        })}
                    </div>

                    {selectedMenu && selectedSubMenu && selectedSubMenu.length > 0 ? (
                        <>
                            <div className="border-[1px]"></div>
                            <div
                                className={cn(
                                    "flex flex-col gap-2",
                                    IsIconType && !haveTextType ? "max-w-80 whitespace-pre-line break-words" : "",
                                )}>
                                <Link
                                    className="hover:text-c2 font-light text-xs text-grayc3"
                                    href={encodeDynamicParts(selectedMenu.attributes[0].linkUrl)}>
                                    {selectedMenu.attributes[0].displayName}
                                </Link>
                                {selectedSubMenu &&
                                    selectedSubMenu.map((subMenu: WebNavigationMenu) => (
                                        <Link
                                            key={subMenu.id + "_" + "selected"}
                                            href={encodeDynamicParts(subMenu.attributes[0].linkUrl)}
                                            className="w-fit text-gray font-light text-base hover:text-c2">
                                            {subMenu.type === "ICON" ? (
                                                <div className="relative">
                                                    <Image
                                                        src={subMenu.attributes[0].imageUrl}
                                                        alt={subMenu.attributes[0].imageAlt}
                                                        width={0}
                                                        height={0}
                                                        style={{ width: "auto", height: "120px" }}
                                                        unoptimized
                                                    />
                                                </div>
                                            ) : null}
                                            {subMenu.attributes[0].displayName}
                                        </Link>
                                    ))}
                            </div>
                        </>
                    ) : null}
                </div>
            </NavigationMenu.Content>
        </NavigationMenu.Item>
    );
};
