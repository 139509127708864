"use client";

import { createContext, useContext, useState } from "react";
import { APIPaymentResult, AXIDCardResult, Cart } from "../../util/services/cart/api.type";
import React from "react";
import {
    addItemToCart,
    calculateUserCart,
    checkout,
    deleteCartItem,
    getUserCart,
    payment2c2p,
    paymentCod,
    setOrderCoupon,
    updateCartItemQuantity,
    updateIdCard,
    updateOrderAddress,
    updateTaxInvoiceAddress,
} from "@/actions/cart";
import {
    AddItemToCartRequestParams,
    DeleteItemRequestParams,
    UpdateItemQuantityRequestParams,
} from "@/util/services/cart/api";
import { useTranslation } from "@/app/i18n/client";

export type PaymentMethod = "credit" | "cod";

interface CartContextData {
    cart?: Cart;
    isUpdating: boolean;
    isDoTransaction: boolean;
    addressId?: string;
    setCart: (cart: Cart) => void;
    getCart: () => Promise<void>;
    calculateCart: () => Promise<void>;
    updateItemQuantity: (params: UpdateItemQuantityRequestParams, calculate?: boolean) => Promise<void>;
    addItem: (params: AddItemToCartRequestParams) => Promise<void>;
    numItemsInCart: () => number;
    deleteItem: (params: DeleteItemRequestParams, calculate?: boolean) => Promise<void>;
    setAddressId: (address: string) => void;
    assignOrderAddressAndCheckout: (
        addressId: string,
        paymentMethod: PaymentMethod,
        taxAddress: string,
        isTax: boolean,
    ) => Promise<APIPaymentResult | undefined>;
    addCoupons: (couponCode: string) => Promise<void>;
    addPromoCode: (promoCode: string) => Promise<void>;
    removeCoupon: (index: number) => Promise<void>;
    removePromoCode: () => Promise<void>;
    isHaveOutOfStockSaleItems: () => boolean;
    getQuantityForItem: (handle: string) => number;
    getOtherLineQuantityForItem: (handle: string, lineId: number) => number;
    checkIdCardWithAX: (encIdCard: string) => Promise<AXIDCardResult>;
    varaintLock: Record<string, boolean>;
}

const CartContext = createContext<CartContextData>({
    isUpdating: false,
    isDoTransaction: false,
    setCart: (cart: Cart) => {},
    getCart: async () => {},
    calculateCart: async () => {},
    updateItemQuantity: async () => {},
    addItem: async () => {},
    numItemsInCart: () => 0,
    deleteItem: async () => {},
    setAddressId: () => {},
    assignOrderAddressAndCheckout: async (
        addressId: string,
        paymentMethod: PaymentMethod,
        taxAddress: string,
        isTax: boolean,
    ) => undefined,
    addCoupons: async (couponCode: string) => {},
    addPromoCode: async (promoCode: string) => {},
    removeCoupon: async (index: number) => {},
    removePromoCode: async () => {},
    isHaveOutOfStockSaleItems: () => false,
    getQuantityForItem: (handle: string) => 0,
    getOtherLineQuantityForItem: (handle: string, lineId: number) => 0,
    checkIdCardWithAX: async (encIdCard: string) => "CONTINUE",
    varaintLock: {},
});

export interface CartProviderProps {
    children: React.ReactNode;
}

export const CartProvider = ({ children }: CartProviderProps) => {
    const { lang } = useTranslation();
    const [cart, setCart] = useState<Cart>();
    const [isUpdating, setUpdating] = useState<boolean>(false);
    const [addressId, setAddressId] = useState<string>();
    const [isDoTransaction, setTransaction] = useState<boolean>(false);
    const [varaintLock, setVariantLock] = useState<Record<string, boolean>>({});

    const getCart = async () => {
        try {
            const result = await getUserCart();
            if (result.success) {
                if (result.result.saleItems.length > 0) {
                    const newLock = result.result.saleItems.reduce(
                        (pre, curr) => {
                            if (varaintLock[curr.msProductVariantId]) {
                                return {
                                    ...pre,
                                    [curr.msProductVariantId]: varaintLock[curr.msProductVariantId],
                                };
                            }

                            return {
                                ...pre,
                                [curr.msProductVariantId]: false,
                            };
                        },
                        {} as Record<string, boolean>,
                    );
                    setVariantLock(newLock);
                } else {
                    setVariantLock({});
                }

                setCart(result.result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const calculateCart = async () => {
        try {
            const result = await calculateUserCart();
            if (result.success) {
                setCart(result.result);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateItemQuantity = async (params: UpdateItemQuantityRequestParams, calculate?: boolean) => {
        setUpdating(true);
        setVariantLock((prev) => {
            return {
                ...prev,
                [params.idVariant]: true,
            };
        });
        try {
            const result = await updateCartItemQuantity(params);
            if (result.success) {
                if (calculate) {
                    const cartResult = await calculateUserCart();
                    if (cartResult.success) {
                        setCart(cartResult.result);
                        setUpdating(false);
                        setVariantLock((prev) => {
                            return {
                                ...prev,
                                [params.idVariant]: false,
                            };
                        });
                    } else {
                        setCart(result.result);
                        setUpdating(false);
                        setVariantLock((prev) => {
                            return {
                                ...prev,
                                [params.idVariant]: false,
                            };
                        });
                    }
                } else {
                    setCart(result.result);
                    setUpdating(false);
                    setVariantLock((prev) => {
                        return {
                            ...prev,
                            [params.idVariant]: false,
                        };
                    });
                }
            } else {
                setUpdating(false);
                setVariantLock((prev) => {
                    return {
                        ...prev,
                        [params.idVariant]: false,
                    };
                });
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            setVariantLock((prev) => {
                return {
                    ...prev,
                    [params.idVariant]: false,
                };
            });
            throw error;
        }
    };

    const addItem = async (params: AddItemToCartRequestParams) => {
        try {
            const result = await addItemToCart(params);
            if (result.success) {
                setCart(result.result);
            } else {
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const numItemsInCart = () => {
        return (cart?.saleItems ?? []).reduce((prev, current) => (prev += current.quantity), 0);
    };

    const deleteItem = async (params: DeleteItemRequestParams, calculate?: boolean) => {
        try {
            setUpdating(true);
            setVariantLock((prev) => {
                return {
                    ...prev,
                    [params.idVariant]: true,
                };
            });
            const result = await deleteCartItem(params);
            if (result.success) {
                if (calculate) {
                    const cartResult = await calculateUserCart();
                    if (cartResult.success) {
                        setCart(cartResult.result);
                        setUpdating(false);
                        setVariantLock((prev) => {
                            return {
                                ...prev,
                                [params.idVariant]: false,
                            };
                        });
                    } else {
                        setCart(result.result);
                        setUpdating(false);
                        setVariantLock((prev) => {
                            return {
                                ...prev,
                                [params.idVariant]: false,
                            };
                        });
                    }
                } else {
                    setCart(result.result);
                    setUpdating(false);
                    setVariantLock((prev) => {
                        return {
                            ...prev,
                            [params.idVariant]: false,
                        };
                    });
                }
            } else {
                setUpdating(false);
                setVariantLock((prev) => {
                    return {
                        ...prev,
                        [params.idVariant]: false,
                    };
                });
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            setVariantLock((prev) => {
                return {
                    ...prev,
                    [params.idVariant]: false,
                };
            });
            throw error;
        }
    };

    const checkIdCardWithAX = async (encIdCard: string): Promise<AXIDCardResult> => {
        setTransaction(true);
        try {
            const resultIdCard = await updateIdCard(encIdCard);
            console.log(`result updateIdCard = ${JSON.stringify(resultIdCard)}`);
            if (!resultIdCard.success) {
                setTransaction(false);
                throw new Error(resultIdCard.error_friendly_message[lang]);
            }
            setTransaction(false);

            if (resultIdCard.result && resultIdCard.result.ax_result) {
                return resultIdCard.result.ax_result;
            }
            throw new Error("ไม่สามารถตรวจสอบเลขบัตรประชาชนได้");
        } catch (error) {
            setTransaction(false);
            console.log(`result updateIdCard error = ${error}`);
            throw error;
        }

        // setTransaction(false);
        // return "FORCE_LOGIN"
    };

    const assignOrderAddressAndCheckout = async (
        addressId: string,
        paymentMethod: PaymentMethod,
        taxAddress: string,
        isTax: boolean,
    ) => {
        if (cart) {
            try {
                setTransaction(true);
                await updateOrderAddress(cart.id, addressId);
                await updateTaxInvoiceAddress(cart.id, taxAddress, isTax);

                const checkoutResult = await checkout();
                if (checkoutResult.success) {
                    const host = `${window.location.protocol}//${window.location.host}`;

                    if (paymentMethod === "credit") {
                        const result = await payment2c2p({
                            saleOrdersId: cart.id,
                            frontendReturnUrl: `${host}/api/payment/confirm`,
                            description: "test payment with 2c2p",
                        });
                        getCart();
                        setTransaction(false);
                        return result;
                    } else {
                        const result = await paymentCod({
                            saleOrdersId: cart.id,
                            frontendReturnUrl: "",
                            description: "",
                        });
                        console.log(`paymentcode = ${JSON.stringify(result)}`);
                        if (result.success) {
                            getCart();
                            setTransaction(false);
                            return result;
                        } else {
                            setTransaction(false);
                            throw new Error(result.error_friendly_message[lang]);
                        }
                    }
                } else {
                    setTransaction(false);
                    throw new Error(checkoutResult.error_friendly_message[lang]);
                }
            } catch (error) {
                setTransaction(false);
                throw error;
            }
        }
    };

    const addCoupons = async (couponCode: string) => {
        setUpdating(true);
        try {
            const result = await setOrderCoupon([...(cart?.discountRemarks ?? []), couponCode], cart?.promoCode ?? "");
            if (result.success) {
                setCart(result.result);
                setUpdating(false);

                if (result.result.errorCode) {
                    throw new Error(result.result.errorCode.message[lang]);
                }
            } else {
                setUpdating(false);
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            throw error;
        }
    };

    const addPromoCode = async (promoCode: string) => {
        setUpdating(true);
        try {
            const result = await setOrderCoupon([...(cart?.discountRemarks ?? [])], promoCode);
            if (result.success) {
                setCart(result.result);
                setUpdating(false);

                if (result.result.errorCode) {
                    throw new Error(result.result.errorCode.message[lang]);
                }
            } else {
                setUpdating(false);
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            throw error;
        }
    };

    const removeCoupon = async (index: number) => {
        setUpdating(true);
        try {
            if ((cart?.discountRemarks ?? []).length <= index) {
                throw new Error("remove_discount_exceed");
            }

            cart?.discountRemarks.splice(index, 1);
            const result = await setOrderCoupon([...(cart?.discountRemarks ?? [])], cart?.promoCode ?? "");
            if (result.success) {
                setCart(result.result);
                setUpdating(false);
            } else {
                setUpdating(false);
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            throw error;
        }
    };

    const removePromoCode = async () => {
        setUpdating(true);
        try {
            const result = await setOrderCoupon([...(cart?.discountRemarks ?? [])], "");
            if (result.success) {
                setCart(result.result);
                setUpdating(false);
            } else {
                setUpdating(false);
                throw new Error(result.error_friendly_message[lang]);
            }
        } catch (error) {
            console.log(error);
            setUpdating(false);
            throw error;
        }
    };

    const isHaveOutOfStockSaleItems = () => {
        let isHaveOutOfStock = false;
        for (let item of cart?.saleItems ?? []) {
            if (item.quantity > item.msProductVariant.inventory - item.msProductVariant.inventory_used) {
                isHaveOutOfStock = true;
                break;
            }
        }
        return isHaveOutOfStock;
    };

    const getQuantityForItem = (handle: string) => {
        return (
            cart?.saleItems
                .filter((item) => item.msProductHandle === handle)
                .reduce((prev, current) => (prev += current.quantity), 0) ?? 0
        );
    };

    const getOtherLineQuantityForItem = (handle: string, lineId: number) => {
        return (
            cart?.saleItems
                .filter((item) => item.msProductHandle === handle && item.lineId !== lineId)
                .reduce((prev, current) => (prev += current.quantity), 0) ?? 0
        );
    };

    return (
        <CartContext.Provider
            value={{
                cart,
                setCart,
                getCart,
                calculateCart,
                updateItemQuantity,
                addItem,
                numItemsInCart,
                deleteItem,
                isUpdating,
                addressId,
                setAddressId,
                assignOrderAddressAndCheckout,
                isDoTransaction,
                addCoupons,
                addPromoCode,
                removeCoupon,
                removePromoCode,
                isHaveOutOfStockSaleItems,
                getQuantityForItem,
                getOtherLineQuantityForItem,
                checkIdCardWithAX,
                varaintLock,
            }}>
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => {
    return useContext(CartContext);
};
