"use client";

import React from "react";
import AddressForm from "../forms/address/address-form";
import { AddressValidatorSchema } from "@/lib/validators/address";

interface Props {
    id?: string;
    onSubmit?: (values: AddressValidatorSchema, e: any) => void;
    defaultValues?: AddressValidatorSchema;
    disableSetDefault?: boolean;
    requireEmail?: boolean;
    updateDefaultValue?: boolean;
    isLoading?: boolean;
}

const AddressEditor = ({
    id,
    onSubmit,
    defaultValues,
    disableSetDefault,
    requireEmail,
    updateDefaultValue,
    isLoading,
}: Props) => {
    return (
        <div>
            <AddressForm
                id={id}
                onSubmit={onSubmit}
                defaultValues={defaultValues}
                disableSetDefault={disableSetDefault}
                requireEmail={requireEmail}
                updateDefaultValue={updateDefaultValue}
                isLoading={isLoading}
            />
        </div>
    );
};

export default AddressEditor;
