"use client";

import { AddressValidatorSchema, addressValidatorSchema } from "@/lib/validators/address";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { cn } from "@/lib/utils";
import { useTranslation } from "@/app/i18n/client";
import { Input } from "@/components/ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useEffect, useState } from "react";
import { getCityData } from "@/actions/address";
import { City } from "@/util/query/address-city-query";
import _groupBy from "lodash.groupby";
import { Switch } from "@/components/ui/switch";

interface Props {
    id?: string;
    onSubmit?: (values: AddressValidatorSchema, e: any) => void;
    defaultValues?: AddressValidatorSchema;
    disableSetDefault?: boolean;
    requireEmail?: boolean;
    updateDefaultValue?: boolean;
    isLoading?: boolean;
}

const AddressForm = ({
    id,
    onSubmit,
    defaultValues,
    disableSetDefault,
    requireEmail,
    updateDefaultValue,
    isLoading,
}: Props) => {
    const { t, lang } = useTranslation();
    const [cityData, setCityData] = useState<City[]>([]);
    const [provinces, setProvinces] = useState<City[]>([]);
    const [districts, setDistricts] = useState<City[]>([]);
    const [selectedProvince, setSelectedProvinced] = useState<City>();
    const [selectedDistrict, setSelectedDistricted] = useState<City>();
    const [isValidZipCode, setIsValidZipCode] = useState<boolean>(true);
    const [needUpdate, setNeedUpdate] = useState<boolean>(true);

    const form = useForm<AddressValidatorSchema>({
        resolver: zodResolver(addressValidatorSchema),
        defaultValues: {
            ...defaultValues,
            country: defaultValues?.country ?? (lang === "th" ? "ประเทศไทย" : "Thailand"),
            is_default: defaultValues?.is_default ?? false,
            email: requireEmail ? "" : "notusedthisvalue@xxx.com",
        },
    });

    const getProvinces = (data: City[]) => {
        const provinces = data
            .reduce((prev, current) => {
                const existing = prev.find((i) => i.stateCode === current.stateCode);
                if (!existing) {
                    prev.push(current);
                }
                return prev;
            }, new Array<City>())
            .sort((a, b) => (lang === "th" ? (a.stateTH < b.stateTH ? -1 : 1) : a.stateEN < b.stateEN ? -1 : 1));
        return provinces;
    };

    useEffect(() => {
        if (needUpdate === true && !isLoading) {
            form.reset(defaultValues);
            if (defaultValues && defaultValues.zip_code) {
                validateZipCode(defaultValues.zip_code);
                form.setValue("province", defaultValues.province);
                form.setValue("stateId", defaultValues.stateId);
                setTimeout(() => form.setValue("district", defaultValues.district), 250);
            }
        }
        setNeedUpdate(updateDefaultValue ?? true);
    }, [defaultValues, updateDefaultValue, isLoading]);

    useEffect(() => {
        const getCityAddressData = async () => {
            const result = await getCityData();
            setCityData(result);
            const provinces = getProvinces(result);
            setProvinces(provinces);
        };
        getCityAddressData();
    }, []);

    useEffect(() => {
        if (form.getValues("zip_code")) {
            setSelectedProvinced(provinces.length > 0 ? provinces[0] : undefined);
            form.setValue("province", provinces.length > 0 ? provinces[0].city_id : "");
            form.setValue("stateId", provinces.length > 0 ? provinces[0].state_id : "");
        }
    }, [provinces]);

    useEffect(() => {
        if (form.getValues("zip_code")) {
            setSelectedDistricted(districts.length > 0 ? districts[0] : undefined);
            form.setValue("district", districts.length > 0 ? districts[0].city_id : "");
        } else {
            setSelectedDistricted(undefined);
            form.setValue("district", "");
        }
    }, [districts]);

    useEffect(() => {
        if (selectedProvince && !form.getValues("zip_code")) {
            const districts = cityData
                .filter((i) => i.stateCode === selectedProvince.stateCode)
                .sort((a, b) => (lang === "th" ? (a.cityTH < b.cityTH ? -1 : 1) : a.cityEN < b.cityEN ? -1 : 1));

            setDistricts(districts);
        }
    }, [selectedProvince]);

    const validateZipCode = (zipCode: string) => {
        if (zipCode === "") {
            setIsValidZipCode(true);
            const provinces = getProvinces(cityData);
            setProvinces(provinces);
            setDistricts([]);

            setSelectedProvinced(undefined);
            setSelectedDistricted(undefined);

            form.setValue("province", "");
            form.setValue("district", "");
            form.setValue("stateId", "");
        } else {
            const filter = cityData
                .filter((i) => i.postcode === zipCode)
                .sort((a, b) => (lang === "th" ? (a.cityTH < b.cityTH ? -1 : 1) : a.cityEN < b.cityEN ? -1 : 1));

            setIsValidZipCode(filter.length > 0);
            setProvinces(filter.length > 0 ? [filter[0]] : []);
            setDistricts(filter);

            form.setValue("province", filter.length > 0 ? filter[0].city_id : "");
            form.setValue("stateId", filter.length > 0 ? filter[0].state_id : "");
            form.setValue("district", filter.length > 0 ? filter[0].city_id : "");
        }
    };

    return (
        <Form {...form}>
            <form
                id={id}
                onSubmit={form.handleSubmit((data, e) => {
                    onSubmit?.(data, e);
                })}
                className="flex flex-col gap-5">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
                    <FormField
                        control={form.control}
                        name="first_name"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.first_name")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={t("address.first_name")}
                                        {...field}
                                        value={field.value || ""}
                                        maxLength={255}
                                        className={cn("form-input", formState.errors.first_name && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="last_name"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.last_name")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={t("address.last_name")}
                                        {...field}
                                        value={field.value || ""}
                                        maxLength={255}
                                        className={cn("form-input", formState.errors.last_name && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="address"
                        render={({ field, formState }) => (
                            <FormItem className="md:col-span-2">
                                <FormLabel className="form-input-label">
                                    {t("address.address")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={t("address.address")}
                                        {...field}
                                        value={field.value || ""}
                                        maxLength={255}
                                        className={cn("form-input", formState.errors.address && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="zip_code"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.zip_code")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={t("address.zip_code")}
                                        {...field}
                                        onChange={(e) => {
                                            validateZipCode(e.target.value);
                                            field.onChange(e);
                                        }}
                                        value={field.value || ""}
                                        maxLength={5}
                                        className={cn("form-input", formState.errors.zip_code && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="province"
                        render={({ field, fieldState, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.province")} <span className="text-price">*</span>
                                </FormLabel>
                                <Select
                                    value={field.value}
                                    disabled={!isValidZipCode}
                                    onValueChange={(value) => {
                                        const province = provinces.find((i) => i.city_id === value);
                                        setSelectedProvinced(province);
                                        field.onChange(value);
                                    }}>
                                    <FormControl>
                                        <SelectTrigger
                                            className={cn(
                                                "w-full form-input rounded-md border border-input",
                                                !field.value ? "text-muted-foreground" : "text-c4",
                                            )}>
                                            <SelectValue
                                                placeholder={t("address.province")}
                                                className="text-muted-foreground"
                                            />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {provinces.map((item) => (
                                            <SelectItem
                                                key={item.city_id}
                                                className="form-input focus:text-c2"
                                                value={item.city_id}>
                                                {lang === "th" ? item.stateTH : item.stateEN}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="district"
                        render={({ field, fieldState, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.district")} <span className="text-price">*</span>
                                </FormLabel>
                                <Select
                                    value={field.value}
                                    disabled={!isValidZipCode || !form.watch("province")}
                                    onValueChange={field.onChange}>
                                    <FormControl>
                                        <SelectTrigger
                                            className={cn(
                                                "w-full form-input rounded-md border border-input",
                                                !field.value ? "" : "opacity-50",
                                            )}>
                                            <SelectValue
                                                placeholder={t("address.district")}
                                                className="text-muted-foreground"
                                            />
                                        </SelectTrigger>
                                    </FormControl>
                                    <SelectContent>
                                        {districts.map((item) => (
                                            <SelectItem
                                                key={item.city_id}
                                                className="form-input focus:text-c2"
                                                value={item.city_id}>
                                                {lang === "th" ? item.cityTH : item.cityEN}
                                            </SelectItem>
                                        ))}
                                    </SelectContent>
                                </Select>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="mobile"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.mobile")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        placeholder={t("address.mobile")}
                                        {...field}
                                        value={field.value || ""}
                                        className={cn("form-input", formState.errors.mobile && "form-input-error")}
                                        maxLength={10}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    <FormField
                        control={form.control}
                        name="country"
                        render={({ field, formState }) => (
                            <FormItem>
                                <FormLabel className="form-input-label">
                                    {t("address.country")} <span className="text-price">*</span>
                                </FormLabel>
                                <FormControl>
                                    <Input
                                        disabled
                                        placeholder={t("address.country")}
                                        {...field}
                                        value={field.value || ""}
                                        className={cn("form-input", formState.errors.country && "form-input-error")}
                                    />
                                </FormControl>
                                <FormMessage translate="yes" />
                            </FormItem>
                        )}
                    />
                    {requireEmail && (
                        <FormField
                            control={form.control}
                            name="email"
                            render={({ field, formState }) => (
                                <FormItem>
                                    <FormLabel className="form-input-label">
                                        {t("address.email")} <span className="text-price">*</span>
                                    </FormLabel>
                                    <FormControl>
                                        <Input
                                            inputMode="email"
                                            placeholder={t("address.email")}
                                            {...field}
                                            value={field.value || ""}
                                            className={cn("form-input", formState.errors.email && "form-input-error")}
                                        />
                                    </FormControl>
                                    <FormMessage translate="yes" />
                                </FormItem>
                            )}
                        />
                    )}
                </div>
                {!disableSetDefault && (
                    <FormField
                        control={form.control}
                        name="is_default"
                        render={({ field, formState }) => (
                            <FormItem className="flex gap-2 items-center">
                                <FormLabel className="text-sm font-light text-grayc3 mt-2">
                                    {t("address.is_default")}
                                </FormLabel>
                                <FormControl>
                                    <Switch
                                        checked={field.value}
                                        onCheckedChange={field.onChange}
                                        className="data-[state=checked]:bg-c2 data-[state=unchecked]:bg-grayc3"
                                    />
                                </FormControl>
                            </FormItem>
                        )}
                    />
                )}
            </form>
        </Form>
    );
};

export default AddressForm;
