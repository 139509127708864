// import { i18n } from "@/i18n.config";
import { ObjectID } from "bson";
import { fallbackLng, languages } from "@/app/i18n/settings";
import { format } from "date-fns";
import { useTranslation } from "@/app/i18n/client";
import { MSProductVariantWithAttribute } from "./query/product-query";
import { LinkTarget, ProductsPromotion } from "@prisma/client";
import { HTMLAttributeAnchorTarget } from "react";
import CryptoJS from "crypto-js";

export const redirectedPathName = (locale: string, pathName: string) => {
    if (!pathName) return "/";

    const pathnameIsMissingLocale = languages.every(
        (locale) => !pathName.startsWith(`/${locale}/`) && pathName !== `/${locale}`,
    );

    if (pathnameIsMissingLocale) {
        if (locale === fallbackLng) return pathName;
        return `/${locale}${pathName}`;
    } else {
        if (locale === fallbackLng) {
            const segments = pathName.split("/");
            const isHome = segments.length === 2;
            if (isHome) return "/";

            segments.splice(1, 1);
            return segments.join("/");
        }

        const segments = pathName.split("/");
        segments[1] = locale;
        return segments.join("/");
    }
};

export const getEasyFormUrl = (CustomerAccount: string) => {
    if (CustomerAccount) {
        const data = JSON.stringify({ customer_account: CustomerAccount });

        const key = CryptoJS.enc.Utf8.parse(`${process.env.NEXT_PUBLIC_EASYFORM_SECRET}`);
        const iv = CryptoJS.enc.Utf8.parse(`${process.env.NEXT_PUBLIC_EASYFORM_IV}`);

        const encrypted = CryptoJS.AES.encrypt(data, key, {
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString();

        const token = encodeURIComponent(encrypted);

        if (token) return `${process.env.NEXT_PUBLIC_EASYFORM_URL}/api/oauth/token?code=${token}`;
    }

    return `${process.env.NEXT_PUBLIC_EASYFORM_URL}`;
};

export const NewObjectID = () => {
    const id = new ObjectID();
    return id.toString();
};

export const updateSearchParams = (type: string, value: string) => {
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(type, value);
    const newPathName = `${window.location.pathname}?${searchParams.toString()}`;
    return newPathName;
};

export const toPriceString = (price: number) => {
    const formatter = new Intl.NumberFormat("th", {
        minimumFractionDigits: 0,
    });

    return formatter.format(price);
};

export const hideMobileNumberDigit = (phoneNumber: string) => {
    return phoneNumber.slice(0, 4) + phoneNumber.slice(4, -2).replace(/./g, "*") + phoneNumber.slice(-2);
};

export const isGuestUser = (email: string) => {
    return email.includes("@dummy.com");
};

/**
 *  Tranform Array To Dictionary
 * @param values Data Array
 * @param selector Node Unquie Key
 * @returns
 */
export const arrayToDict = <T extends { [K in keyof T]: string | number | symbol | any }, K extends keyof T>(
    values: T[],
    selector: K,
): Record<string, T> => {
    return values.reduce(
        (pre, curr) => {
            return {
                ...pre,
                [curr[selector]]: curr,
            };
        },
        {} as Record<T[K], T>,
    );
};

export const DateToString = (date: Date | null, formatString: string) => {
    if (!date) return "";
    const formatter = format(date, formatString);
    return formatter;
};

export const DateText = (date: any, showtime = false, shortmonth = false, locale = "th") => {
    if (date == null) return "";
    let dt = format(new Date(date), "dd MM yyyy HH:mm:ss");
    let strDate = dt.split(" ");

    const month_thai = [
        "มกราคม",
        "กุมภาพันธ์",
        "มีนาคม",
        "เมษายน",
        "พฤษภาคม",
        "มิถุนายน",
        "กรกฎาคม",
        "สิงหาคม",
        "กันยายน",
        "ตุลาคม",
        "พฤศจิกายน",
        "ธันวาคม",
    ];

    const month_english = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const month_short_english = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const month_short_thai = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
    ];

    let mont_use = [];

    if (locale == "th") {
        if (shortmonth) {
            mont_use = month_short_thai;
        } else {
            mont_use = month_thai;
        }
    } else {
        if (shortmonth) {
            mont_use = month_short_english;
        } else {
            mont_use = month_english;
        }
    }
    const dateno = parseInt(strDate[1]);
    const years = parseInt(strDate[2]);
    const budhayearsAdd = 543;
    let strResult =
        strDate[0] + " " + mont_use[dateno - 1] + " " + (years + budhayearsAdd) + " " + (showtime ? strDate[3] : "");

    return strResult;
};

export const setMarkXBankAccount = (account: string) => {
    if (!account) return "";
    var markX = account
        .split("")
        .map((char, index) => {
            return index < 6 ? "*" : char;
        })
        .join("");
    markX = [markX.slice(0, 3), "-", markX.slice(3)].join("");
    markX = [markX.slice(0, 5), "-", markX.slice(5)].join("");
    markX = [markX.slice(0, 11), "-", markX.slice(11)].join("");

    return markX;
};

export const setMarkXIdCard = (idCard: string) => {
    if (!idCard) return "";
    var markX = idCard
        .split("")
        .map((char, index) => {
            return index < 6 ? "*" : char;
        })
        .join("");
    markX = [markX.slice(0, 1), "-", markX.slice(1)].join("");
    markX = [markX.slice(0, 6), "-", markX.slice(6)].join("");
    markX = [markX.slice(0, 12), "-", markX.slice(12)].join("");
    markX = [markX.slice(0, 15), "-", markX.slice(15)].join("");

    return markX;
};

export const filterVariantOfPromotion = (
    productVariant: MSProductVariantWithAttribute[],
    promotions: ProductsPromotion[],
) => {
    return productVariant.filter((variant) => {
        if (promotions.length > 0) {
            const date = new Date();
            const isDuringPromotion = date >= promotions[0].StartAt && date <= promotions[0].EndAt;
            return isDuringPromotion ? variant.show_during_promotion : variant.show_after_promotion;
        }
        return true;
    });
};

export const getLinkTarget = (linkTarget: LinkTarget): HTMLAttributeAnchorTarget => {
    let target = "_self";
    if (linkTarget === LinkTarget.BLANK) {
        target = "_blank";
    } else if (linkTarget === LinkTarget.PARENT) {
        target = "_parent";
    } else if (linkTarget === LinkTarget.TOP) {
        target = "_top";
    }
    return target;
};

export function shuffle<T>(array: T[]): T[] {
    let currentIndex = array.length,
        randomIndex;

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
    }

    return array;
}

export const verifyThaiId = (thaiId: string): boolean => {
    const m = thaiId.match(/(\d{12})(\d)/);
    if (!m) {
        console.warn("Bad input from user, invalid thaiId=", thaiId);
        return false;
    }
    const digits = m[1].split("");
    const sum = digits.reduce((total: number, digit: string, i: number) => {
        return total + (13 - i) * +digit;
    }, 0);
    const lastDigit = `${(11 - (sum % 11)) % 10}`;
    const inputLastDigit = m[2];
    if (lastDigit !== inputLastDigit) {
        console.warn("Bad input from user, invalid checksum thaiId=", thaiId);
        return false;
    }
    return true;
};

export const truncateString = (str: string, n: number): string => {
    return str.length > n ? str.slice(0, n - 1) + " ..." : str;
};

export const copyToClipboard = async (str: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(str);
        return true;
    } catch (err: any) {
        throw new Error(err.message);
    }
};

export const copyToIOSClipboard = async (str: string): Promise<boolean> => {
    try {
        const clipboardItem = new ClipboardItem({
            "text/plain": new Promise(async (resolve) => {
                resolve(new Blob([str], { type: "text/plain" }));
            }),
        });
        await navigator.clipboard.write([clipboardItem]);
        return true;
    } catch (err: any) {
        throw new Error(err.message);
    }
};

export const encodeDynamicParts = (url: string): string => {
    const regexCollection = /^\/collections\/(.+)$/;
    const matchCollection = url.match(regexCollection);

    if (matchCollection) {
        const encodedUrl = `/collections/${encodeURIComponent(matchCollection[1])}`;
        return encodedUrl;
    }

    const regexCollectionProduct = /^\/collections\/([^/]+)\/products\/([^/]+)$/;
    const matchCollectionProduct = url.match(regexCollectionProduct);

    if (matchCollectionProduct) {
        const encodedUrl = `/collections/${encodeURIComponent(matchCollectionProduct[1])}/products/${encodeURIComponent(
            matchCollectionProduct[2],
        )}`;
        return encodedUrl;
    }

    const regexProduct = /^\/products\/(.+)$/;
    const matchProduct = url.match(regexProduct);

    if (matchProduct) {
        const encodedUrl = `/products/${encodeURIComponent(matchProduct[1])}`;
        return encodedUrl;
    }

    return url;
};

export function isEncoded(url: string) {
    try {
        const pathname = new URL(url, "http://localhost").pathname;
        const parts = pathname.split("/").filter(Boolean);
        return parts.every((part) => part === encodeURIComponent(decodeURIComponent(part)));
    } catch {
        return false;
    }
}
