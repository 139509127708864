"use client";

import { cn } from "@/lib/utils";
import "./nav-ulife.css";
import Link from "next/link";
import Image from "next/image";
import { WebNavigationMenu } from "@/util/query/navigation-query";
import { encodeDynamicParts, getLinkTarget } from "@/util/util";
import { NavLink } from "./nav-link";

type Props = {
    menu: WebNavigationMenu;
    index: number;
};

export const DisplayAttributesVerical = ({ menu }: Props) => {
    return menu.type === "TEXT" && menu.attributes && menu.attributes.length > 0 ? (
        <NavLink
            className="w-full self-start text-base whitespace-pre-line"
            href={encodeDynamicParts(menu.attributes[0].linkUrl)}
            target={getLinkTarget(menu.linkTarget || "SELF")}>
            {menu.attributes[0].displayName}
        </NavLink>
    ) : null;
};

export const DisplayMenuListVerical = ({ menu, index }: Props) => {
    return (
        <div>
            <Link
                href={encodeDynamicParts(menu.attributes[0].linkUrl)}
                className="flex gap-1 items-center text-c4 text-base focus:text-c2 font-light hover:text-c2 focus:font-semibold pb-2">
                {menu.attributes[0].displayName}
            </Link>
            <div className="flex w-full pl-5">
                <div className="flex flex-row w-full gap-10 mx-auto max-w-screen-2xl">
                    <div
                        className={cn(
                            "flex w-full",
                            menu.subMenuList.find((value) => value.type === "ICON")
                                ? "flex-wrap gap-2"
                                : "flex-col gap-2",
                        )}>
                        {menu.subMenuList.map((subMenu: any, indexMenu: number) => {
                            return (
                                <div key={`nav_vartical_submenu_div_${index}_${indexMenu}_${subMenu.id}`}>
                                    <NavLink
                                        href={encodeDynamicParts(subMenu.attributes[0].linkUrl)}
                                        target={getLinkTarget(subMenu.linkTarget || "SELF")}
                                        className={cn(
                                            "flex flex-col gap-2 w-full text-sm hover:text-c2 font-light text-start self-start whitespace-pre-line text-gray",
                                        )}>
                                        <div className="flex flex-row gap-2">
                                            {subMenu.type === "ICON" ? (
                                                <div className="relative">
                                                    <Image
                                                        src={subMenu.attributes[0].imageUrl}
                                                        alt={subMenu.attributes[0].imageAlt}
                                                        width={0}
                                                        height={0}
                                                        style={{ width: "auto", height: "30px" }}
                                                        unoptimized
                                                    />
                                                </div>
                                            ) : null}
                                            <span className={cn(subMenu.type === "ICON" ? "self-center" : "")}>
                                                {subMenu.attributes[0].displayName}
                                            </span>
                                        </div>
                                    </NavLink>
                                    {subMenu.subMenuList && subMenu.subMenuList.length > 0 && (
                                        <div className="flex flex-col w-full gap-2 self-start pl-5">
                                            {subMenu.subMenuList.map(
                                                (subMenu: WebNavigationMenu, indexSubmenu: number) => (
                                                    <Link
                                                        key={`nav_vartical_submenu_${index}_${indexMenu}_${indexSubmenu}_${subMenu.id}`}
                                                        href={encodeDynamicParts(subMenu.attributes[0].linkUrl)}
                                                        className="w-fit text-gray font-light text-sm hover:text-c2  whitespace-pre-line">
                                                        {subMenu.attributes[0].displayName}
                                                    </Link>
                                                ),
                                            )}
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};
